import {IS_LOGGED_IN, IS_STAGING, PLAYER_ID, USE_NEW_BANKING_EVENT_LISTENER} from "Setup/config";
import {sendGA4Event} from "Helpers/gaEvents";
import {sendLogglyEvent} from "Helpers/loggly";
import ErrorBoundary from "Components/ErrorBoundary";
import {useEffect, useRef} from "react";
import useBanking from "Hooks/api/useBanking";
import {setHasDeposited} from "Redux/actions/morpheus";
import {useDispatch} from "react-redux";

const sendMessagesFromSafechargeToLoggly = true;
const sendLogglyEventBankingEventListener = (message, json) => sendMessagesFromSafechargeToLoggly ? sendLogglyEvent('Safecharge', message, json) : null;

const _BankingEventListener = ({hasError}) => {
    const dispatch = useDispatch();
    const {hasDeposited} = useBanking({});
    const isFTDRef = useRef(!hasDeposited);

    if (hasError) return null;
    if (!IS_LOGGED_IN || !USE_NEW_BANKING_EVENT_LISTENER) return null;

    const _setHasDeposited = () => {
        if(isFTDRef.current) {
            dispatch(setHasDeposited());
            isFTDRef.current = false;
        }
    };

    const listenForBankingWindowEvents = (evt) => {
        if (evt.origin.includes("safecharge") && evt?.data) {
            try {
                const parsedMessage = JSON.parse(evt.data);

                if (parsedMessage) {
                    if (parsedMessage?.payload?.action) {
                        const newAction = parsedMessage.payload.action;
                        const actionsToWatch = ["made deposit", "made first deposit"];

                        if (actionsToWatch.includes(newAction)) {
                            let depositValue = 0;
                            if (parsedMessage?.payload?.deposit_amount) {
                                depositValue = Number(parsedMessage.payload.deposit_amount).toFixed(2);

                                console.log(`%cSuccessful Deposit : £${depositValue}`, 'color: green;');
                                console.log(parsedMessage);
                            }

                            if (isFTDRef.current) {
                                if (!IS_STAGING) {
                                    window.dataLayer.push({event: 'firstDeposit_v2'});
                                }
                            }

                            sendGA4Event('purchase_test', {
                                currency: 'GBP',
                                player_id: PLAYER_ID,
                                value: depositValue
                            });

                            sendLogglyEventBankingEventListener(`${isFTDRef.current ? 'First ' : ''}Deposit from the banking client`, parsedMessage);
                            _setHasDeposited();
                        }
                    }

                    sendLogglyEventBankingEventListener('Message from the banking client', parsedMessage);
                }
            } catch (error) {
                sendLogglyEventBankingEventListener('Error in bankingModalListener', error);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', listenForBankingWindowEvents, false);

        return () => {
            window.removeEventListener('message', listenForBankingWindowEvents, false);
        }
    }, []);

    return null;
};

const BankingEventListener = () => (
    <ErrorBoundary>
        <_BankingEventListener/>
    </ErrorBoundary>
);

export default BankingEventListener;